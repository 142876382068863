import { useState } from "react";
import "./App.css";
import LOGO from "./assets/magicbell_logo.png";
import IMAGE from "./assets/main_image.svg";
import toast from "react-hot-toast";
import axios from "axios";

let BASE_URL = "https://magicbell.nexuslinkdev.com:3043";

function App() {
  const [formData, setFormData] = useState({});
  const [error, setError] = useState({});

  const handleChange = (name, value) => {
    setFormData({ ...formData, [name]: value });
  };

  const validation = () => {
    let isError = false;
    let errors = {};

    const { serviceJson, deviceToken, title, message } = formData;

    if (!serviceJson) {
      errors = { ...errors, serviceJson: "please enter the service json !" };
      isError = true;
    } else {
      errors = { ...errors, serviceJson: "" };
    }
    if (!deviceToken) {
      errors = { ...errors, deviceToken: "please enter the device token !" };
      isError = true;
    } else {
      errors = { ...errors, deviceToken: "" };
    }
    if (!title) {
      errors = { ...errors, title: "please enter the title !" };
      isError = true;
    } else {
      errors = { ...errors, title: "" };
    }
    if (!message) {
      errors = { ...errors, message: "please enter the message !" };
      isError = true;
    } else {
      errors = { ...errors, message: "" };
    }

    setError(errors);

    return isError;
  };

  const onSubmit = async () => {
    if (!validation()) {
      try {
        const response = await axios.post(`${BASE_URL}/notification`, formData);
        toast.success(response.data.message);
      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.message || "something went wrong");
      }
    }
  };

  return (
    <div>
      <div class="container header">
        <img src={LOGO} alt="Logo" />
      </div>

      <div class="container-fluid">
        <div class="row main-container">
          <div class="col-md-6 illustration d-flex flex-column align-items-center">
            <img src={IMAGE} alt="Illustration" width={500} />
            <h1 className="text-center">Test Your Firebase Push Notifications with Ease!</h1>
          </div>

          <div class="col-md-6">
            <div class="form-container">
              <h4>Simple interface to Test Mobile Push Notification</h4>
              <form>
                <div class="form-group">
                  <label for="serviceJson">
                    Service Json{" "}
                    <span
                      data-toggle="tooltip"
                      data-placement="bottom"
                      title="Project setting > Service account > Generate new private key"
                    >
                      ⓘ
                    </span>{" "}
                    <span className="error">*</span>
                  </label>
                  <textarea
                    type="text"
                    rows={8}
                    class="form-control"
                    id="serviceJson"
                    placeholder="Service Json"
                    value={formData?.serviceJson}
                    onChange={(e) => handleChange("serviceJson", e.target.value)}
                  />
                  <p className="error-msg">{error?.serviceJson}</p>
                </div>
                <div class="form-group">
                  <label for="deviceToken">
                    FCM Registration Token (Device Token) <span className="error">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="deviceToken"
                    placeholder="Device Token"
                    value={formData?.deviceToken}
                    onChange={(e) => handleChange("deviceToken", e.target.value)}
                  />
                  <p className="error-msg">{error?.deviceToken}</p>
                </div>
                <div class="form-group">
                  <label for="title">
                    Title <span className="error">*</span>
                  </label>
                  <input
                    type="text"
                    class="form-control"
                    id="title"
                    placeholder="Notification Title"
                    value={formData?.title}
                    onChange={(e) => handleChange("title", e.target.value)}
                  />
                  <p className="error-msg">{error?.title}</p>
                </div>
                <div class="form-group">
                  <label for="message">
                    Message <span className="error">*</span>
                  </label>
                  <input
                    class="form-control"
                    id="message"
                    placeholder="Notification Message"
                    value={formData?.message}
                    onChange={(e) => handleChange("message", e.target.value)}
                  ></input>
                  <p className="error-msg">{error?.message}</p>
                </div>
                {/* <div class="form-group">
                  <label for="clickAction">Click Action URL (optional)</label>
                  <input
                    type="text"
                    class="form-control"
                    id="clickAction"
                    placeholder="URL to redirect"
                  />
                </div>
                <div class="form-group">
                  <label for="imageUrl">Image URL (optional)</label>
                  <input type="text" class="form-control" id="imageUrl" placeholder="Image URL" />
                </div>
                <div class="form-group">
                  <label for="data">Data (optional)</label>
                  <textarea class="form-control" id="data" placeholder="Optional data"></textarea>
                </div> */}
                <button type="button" class="btn btn-primary btn-block" onClick={onSubmit}>
                  Send Notification
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* <div class="footer">
        <p>😀Just Growing With The Flow😀</p>
      </div> */}
    </div>
  );
}

export default App;
